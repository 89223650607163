import * as React from "react"
import {Card, Col, Container, Ratio, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import CalendarVideo from "../videos/calendar_management.mp4"
import DocumentVideo from "../videos/document_,management.mp4"
import ArticleVideo from "../videos/article_management.mp4"

// markup
const DocsPage = () => {
    return <Layout>
        <Seo title={'Dokumentation'} meta={[{name: 'robots', content: 'noindex,nofollow'}]}/>
        <Container>
            <h1 className={'mt-5'}>Dokumentation</h1>
            <p className={'mb-5 display-6'}>Dies ist eine Video-basierte Dokumentation, die Sie bei der Inhaltserstellung und der Benutzerverwaltung unterstützt.</p>

            <Row xs={1}>
                <Col className="my-5" md={{span: 8, offset: 2}}>
                    <h2 className="text-center display-3">Mitteilungen</h2>
                    <Ratio aspectRatio="4x3">
                        <video controls>
                            <source src={ArticleVideo} type="video/mp4" />
                        </video>
                    </Ratio>
                </Col>
                <Col className="my-3" xs={{span: 8, offset: 2}} md={{span: 6, offset: 3}}>
                    <hr/>
                </Col>
            </Row>

            <Row xs={1} md={2}>
                <Col className="my-5" md={{span: 8, offset: 2}}>
                    <h2 className="text-center display-3">Dokumente</h2>
                    <Ratio aspectRatio="4x3">
                        <video controls>
                            <source src={DocumentVideo} type="video/mp4" />
                        </video>
                    </Ratio>
                </Col>
                <Col className="my-3" xs={{span: 8, offset: 2}} md={{span: 6, offset: 3}}>
                    <hr/>
                </Col>
            </Row>

            <Row xs={1} md={2}>
                <Col className="my-5" md={{span: 8, offset: 2}}>
                    <h2 className="text-center display-3">Termine</h2>
                    <Ratio aspectRatio="4x3">
                        <video controls>
                            <source src={CalendarVideo} type="video/mp4" />
                        </video>
                    </Ratio>
                </Col>
            </Row>
        </Container>
    </Layout>
}

export default DocsPage
